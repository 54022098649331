
import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'

class Promos extends React.Component {
    render() {

        const image1 = getImage(this.props.promo1)
        const image2 = getImage(this.props.promo2)
        const image3 = getImage(this.props.promo3)

        const bgImage1 = convertToBgImage(image1)
        const bgImage2 = convertToBgImage(image2)
        const bgImage3 = convertToBgImage(image3)
        return (
            <Row>
                <Col md={6} className={`d-flex mb-md-0 mb-3`}>
                    <BackgroundImage Tag="div" className={`w-100`} {...bgImage1} preserveStackingContext>
                        <div className={`p-5 bg-dark bg-opacity-50 w-100 h-100`}>
                            <p className={`text-white fs-4 fw-light mt-5`}>New arrivals<br />are now in!</p>
                            <Link to="/shop"><Button variant="light" className={`rounded-pill px-3 text-uppercase fw-bold`}><small>Show Collection</small></Button></Link>
                        </div>
                    </BackgroundImage>
                </Col>
                <Col md={3} className={`d-flex mb-md-0`}>
                    <BackgroundImage Tag="div" className={`w-100`} {...bgImage2} preserveStackingContext>
                        <div className={`p-5 bg-dark bg-opacity-50 w-100 h-100`}>
                            <p className={`text-white fs-4 fw-light mt-5`}>Accessories<br />$29.99</p>
                            <Link to="/shop"><Button variant="light" className={`rounded-pill px-3 text-uppercase fw-bold`}><small>Show Collection</small></Button></Link>
                        </div>
                    </BackgroundImage>
                </Col>
                <Col md={3} className={`d-flex mb-md-0`}>
                    <BackgroundImage Tag="div" className={`w-100`} {...bgImage3} preserveStackingContext>
                        <div className={`p-5 bg-dark bg-opacity-50 w-100 h-100`}>
                            <p className={`text-white fs-4 fw-light mt-5`}>Sale this<br />summer</p>
                            <Link to="/shop"><Button variant="light" className={`rounded-pill px-3 text-uppercase fw-bold`}><small>Show Collection</small></Button></Link>
                        </div>
                    </BackgroundImage>
                </Col>
            </Row>
        )
    }
}
export default Promos