
import React from 'react'
import { Row, Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShippingFast, faHandshake, faHandHoldingUsd, faAnchor } from '@fortawesome/free-solid-svg-icons'


class Benefits extends React.Component {
    render() {
        return (
            <Row className={`my-5`}>
                <h4 className={`text-center fw-light my-5`}>Why should you choose us?</h4>
                <Row>
                    <Col md={3} sm={6}>
                        <div className={`p-3 mb-5 bg-dark rounded bg-opacity-10 d-inline-flex`}><FontAwesomeIcon className={`fs-2 text-black`} icon={faShippingFast} /></div>
                        <p><strong>Free Shipping</strong></p>
                        <p className={`text-muted`}>All purchases over $199 are eligible for free shipping via Canada Post.</p>
                    </Col>
                    <Col md={3} sm={6}>
                        <div className={`p-3 mb-5 bg-warning rounded bg-opacity-10 d-inline-flex`}><FontAwesomeIcon className={`fs-2 text-warning`} icon={faHandshake} /></div>
                        <p><strong>Easy Payments</strong></p>
                        <p className={`text-muted`}>All payments are processed instantly over a secure payment protocol.</p>
                    </Col>
                    <Col md={3} sm={6}>
                        <div className={`p-3 mb-5 bg-dark rounded bg-opacity-10 d-inline-flex`}><FontAwesomeIcon className={`fs-2 text-black`} icon={faHandHoldingUsd} /></div>
                        <p><strong>Money-Back Guarantee</strong></p>
                        <p className={`text-muted`}>If an item arrived damaged or you've changed your mind, you can send it back for a full refund.</p>
                    </Col>
                    <Col md={3} sm={6}>
                        <div className={`p-3 mb-5 bg-dark rounded bg-opacity-10 d-inline-flex`}><FontAwesomeIcon className={`fs-2 text-black`} icon={faAnchor} /></div>
                        <p><strong>Finest Quality</strong></p>
                        <p className={`text-muted`}>Designed to last, each of our products has been crafted with the finest materials.</p>
                    </Col>
                </Row>
            </Row>
        )
    }
}
export default Benefits