import React from "react"
import Layout from '../components/layout'
import Promos from "../components/promos"
import Benefits from "../components/benefits"
import { Container } from "react-bootstrap"
import Signup from "../components/newsletter-signup"
import { useStore } from "react-redux"
import ProductSlider from "../components/product-slider"

import { graphql, useStaticQuery } from 'gatsby'
import HeroSlider from "../components/hero-slider"

const WpFrontPage = () => {
    const store = useStore()
    const {promo1, promo2, promo3, allWpSimpleProduct, placeholder, hero } = useStaticQuery(
        graphql`
        query {
            placeholder: file(relativePath: {eq: "placeholder.png"}) {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  placeholder:BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            promo1: file(relativePath: {eq: "promo-1.jpeg"}) {
                childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder:BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
            promo2: file(relativePath: {eq: "promo-2.jpeg"}) {
                childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder:BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
            promo3: file(relativePath: {eq: "promo-3.jpeg"}) {
                childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder:BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          hero: file(relativePath: {eq: "hero.jpeg"}) {
            childImageSharp {
          gatsbyImageData(
            width: 1920
            placeholder:BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
          allWpSimpleProduct {
            totalCount
            nodes {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 450, placeholder: BLURRED, formats: AUTO)
                  }
                }
              }
              name
              slug
              price
              salePrice
              onSale
              regularPrice
              allPaBrand {
                nodes {
                  name
                }
              }
            }
          }
        }`
    )
    console.log(store.getState().total)
    return (
        <Layout>
            <HeroSlider heroImage={hero} />
            <Container>
            <Promos promo1={promo1} promo2={promo2} promo3={promo3} />
            <div className={`mt-4 pt-4`}>
            <ProductSlider widgetTitle={`Products selected just for you`} products={allWpSimpleProduct.nodes.slice(0, 4)} placeholder={placeholder} />
            </div>
            <Benefits />
            <Signup background={promo1} />
            </Container>
        </Layout>
    )
}
export default WpFrontPage