
import React from 'react'
import { heroFix, heroCorner } from './layout.module.css'
import { Row, Col, Button, Ratio, Container } from 'react-bootstrap'
import { getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShippingFast, faHandshake, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons'

class HeroSlider extends React.Component {
    render() {

        const heroImage = getImage(this.props.heroImage)
        const bgHeroImage = convertToBgImage(heroImage)

        return (
            <>
            <Ratio aspectRatio={50} className={`mt-0 ${heroFix} mb-5 d-lg-block d-none`}>
                <Col className={`d-flex`} >
                    <BackgroundImage Tag="div" className={`w-100 h-100`} {...bgHeroImage} preserveStackingContext>

                        <div className={`bg-dark bg-opacity-50 w-100 d-flex flex-column h-100`}>

                            <Container>
                                <Row>
                                    <Col lg={8} xl={4}>
                                        <h1 className={`text-white mt-5 display-3 fw-bold mb-5`}>Sale of the Summer Collection</h1>
                                        <Link to="#"><Button variant="light" className={`rounded-pill px-3 text-uppercase fw-bold`}><small>Show Collection</small></Button></Link>
                                    </Col>
                                </Row>

                            </Container>


                            <Col xl={9} md={11} className={`bg-white p-xl-5 p-3 mt-auto ${heroCorner}`}>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col md={2}>
                                                <div className={`p-3 mb-5 bg-dark rounded bg-opacity-10 d-inline-flex`}><FontAwesomeIcon className={`fs-2 text-black`} icon={faShippingFast} /></div>
                                            </Col>
                                            <Col md={8}>
                                                <p><strong>Free Shipping</strong></p>
                                                <p className={`text-muted`}>All purchases over $199 are eligible for free shipping via Canada Post.</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col md={2}>
                                                <div className={`p-3 mb-5 bg-warning rounded bg-opacity-10 d-inline-flex`}><FontAwesomeIcon className={`fs-2 text-warning`} icon={faHandshake} /></div>
                                            </Col>
                                            <Col md={8}>
                                                <p><strong>Easy Payments</strong></p>
                                                <p className={`text-muted`}>All payments are processed instantly over a secure payment protocol.</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    </BackgroundImage>
                </Col>
            </Ratio>
            <Ratio aspectRatio="16x9" className={`mt-0 ${heroFix} mb-5 d-lg-none d-block`}>
                <Col className={`d-flex`} >
                    <BackgroundImage Tag="div" className={`w-100 h-100`} {...bgHeroImage} preserveStackingContext>

                        <div className={`bg-dark bg-opacity-50 w-100 d-flex flex-column h-100`}>

                            <Container>
                                <Row>
                                    <Col md={4}>
                                        <h1 className={`text-white mt-5 display-3 fw-bold mb-5`}>Sale of the Summer Collection</h1>
                                        <Link to="#"><Button variant="light" className={`rounded-pill px-3 text-uppercase fw-bold`}><small>Show Collection</small></Button></Link>
                                    </Col>
                                </Row>

                            </Container>
                        </div>
                    </BackgroundImage>
                </Col>
            </Ratio>
            </>
        )
    }
}
export default HeroSlider